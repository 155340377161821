.result {
  padding: 54px 0px 86px 0px;
  width: 1260px;
  height: 100%; }
  @media (max-width: 599px) {
    .result {
      width: 100%;
      padding: 25px 0px 15px 0px; } }
  .result__wrapper {
    background-color: white;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding: 40px 57px 15px 57px;
    height: auto; }
    @media (max-width: 599px) {
      .result__wrapper {
        background-color: unset;
        box-shadow: unset;
        border-radius: unset;
        padding: 0px; } }
  .result__message {
    margin: auto;
    width: 431px;
    text-align: center;
    margin-bottom: 2rem; }
    @media (max-width: 599px) {
      .result__message {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 21px; } }
  .result__list {
    margin: auto;
    height: 100%; }
  .result__more {
    margin-top: 30px;
    margin-bottom: 40px;
    text-align: center; }
    @media (max-width: 599px) {
      .result__more {
        margin-top: 15px;
        margin-bottom: 100px; } }
    .result__more a {
      font-size: 0.875rem;
      text-decoration: underline;
      margin-top: 16px;
      margin-bottom: 32px;
      color: black;
      font-weight: 600; }
      @media (max-width: 599px) {
        .result__more a {
          margin-top: 0px;
          margin-bottom: 0px; } }
  .result__footer {
    margin-left: 20px;
    margin-right: 20px; }
    @media (max-width: 599px) {
      .result__footer {
        margin-left: 0px;
        margin-right: 0px;
        text-align: center;
        left: 0;
        bottom: 0px;
        position: fixed;
        height: 81px;
        background: white;
        width: 100%; } }
    .result__footer--wrapper {
      border-top: 1px solid #D2D2D2;
      padding-left: 0px;
      padding-top: 14px;
      height: 100%; }
      @media (max-width: 599px) {
        .result__footer--wrapper {
          padding-left: 15px;
          text-align: left; } }
  .result__reset {
    height: 51px;
    width: 159px;
    border: 1px solid #6E2585;
    background-color: white;
    color: #6E2585;
    border-radius: 25px;
    padding-left: 20px;
    font-size: 1.125rem; }
    .result__reset:hover {
      background-color: rgba(110, 37, 133, 0.1);
      color: #6E2585;
      border: 1px solid #6E2585; }
    .result__reset:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 189, 0.5);
      color: #6E2585; }
    .result__reset:not(:disabled):not(.disabled):active {
      background-color: rgba(110, 37, 133, 0.1);
      color: #6E2585;
      border: 1px solid #6E2585; }
    .result__reset img {
      margin-right: 19px; }

.result-item {
  box-sizing: border-box;
  border: 1px solid #D3D1D1;
  border-radius: 6px;
  background-color: white;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  width: 270px;
  height: 430px;
  float: left;
  margin: 0px 8px 17px 8px;
  text-align: center;
  padding: 20px 14px; }
  @media (max-width: 599px) {
    .result-item {
      width: 45%;
      height: 271.5px;
      padding: 12px 13px;
      margin: 0px 2.4% 17px 2.4%; } }
  .result-item:hover {
    border: 2px solid #6E2585;
    background-color: #FBEFFF; }
  .result-item__wrapper {
    height: 100%; }
  .result-item__wrapper-image {
    height: 150px;
    position: relative; }
    @media (max-width: 599px) {
      .result-item__wrapper-image {
        height: 105px; } }
  .result-item__name {
    font-size: 1.125rem;
    font-weight: 600;
    color: black;
    height: 60px; }
    @media (max-width: 599px) {
      .result-item__name {
        font-size: 0.75rem;
        height: 35px; } }
  .result-item__image {
    max-height: 150px;
    max-width: 200px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
    @media (max-width: 599px) {
      .result-item__image {
        max-height: 100%;
        max-width: 100%; } }
  .result-item__description {
    font-size: 0.875rem;
    height: 127px;
    overflow: hidden;
    display: -webkit-box;
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
    /* autoprefixer: on */
    -webkit-line-clamp: 6; }
    @media (max-width: 599px) {
      .result-item__description {
        font-size: 0.5625rem;
        height: 78px; } }
  .result-item__button {
    width: 230px;
    height: 50px;
    font-size: 1rem;
    font-weight: 300;
    border-radius: 25px;
    background-color: #6E2585;
    border-color: #6E2585; }
    @media (max-width: 599px) {
      .result-item__button {
        height: 31px;
        width: 100%;
        font-size: 10px; } }
    .result-item__button:hover {
      background-color: #6E2585;
      border-color: #6E2585; }
    .result-item__button:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 189, 0.5); }
    .result-item__button:not(:disabled):not(.disabled):active {
      background-color: #6E2585;
      border-color: #6E2585; }
