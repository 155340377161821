@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,300;0,400;0,700;0,800;0,900;1,600&display=swap");
html, body {
  height: 100%; }

body {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  background-color: #EEEEEE;
  font-size: 1rem; }
  @media (max-width: 599px) {
    body {
      background-color: white; } }

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: 400;
  margin-bottom: 0; }

h1, .h1 {
  font-size: 2.1875rem;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 800; }

h2, .h2 {
  font-size: 1.875rem; }

h4, .h4 {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 800;
  color: black; }

label {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 300; }

.root {
  height: 100%; }

.button_main {
  height: 40px;
  width: 172px;
  border-radius: 25px;
  background-color: #6E2585;
  border-color: #6E2585;
  font-size: 1.125rem;
  margin-right: 15px; }
  .button_main:hover {
    background-color: #6E2585;
    border-color: #6E2585; }
  .button_main:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 189, 0.5); }
  .button_main:not(:disabled):not(.disabled):active {
    background-color: #6E2585;
    border-color: #6E2585; }

.btn-primary.disabled, .btn-primary:disabled {
  background-color: #6E2585;
  border-color: #6E2585; }
