.startover {
  top: 29%;
  background: white;
  position: relative;
  width: 600px;
  height: 400px;
  margin: auto;
  text-align: center;
  border-radius: 6px; }
  @media (max-width: 599px) {
    .startover {
      width: 90%; } }
  .startover__image {
    padding-top: 55px;
    padding-bottom: 45px; }
    .startover__image img {
      height: 75px; }
  .startover__message {
    color: black;
    font-size: 24px;
    width: 290px;
    margin: auto;
    line-height: 1.25;
    padding-bottom: 35px;
    font-weight: 400; }
    @media (max-width: 599px) {
      .startover__message {
        width: 80%; } }
  .startover__proceed {
    width: 268px;
    height: 50px;
    font-size: 1.125rem;
    font-weight: 300;
    border-radius: 25px;
    background-color: #6E2585;
    border-color: #6E2585; }
    @media (max-width: 599px) {
      .startover__proceed {
        width: 77%; } }
    .startover__proceed:hover {
      background-color: #6E2585;
      border-color: #6E2585; }
    .startover__proceed:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 189, 0.5); }
    .startover__proceed:not(:disabled):not(.disabled):active {
      background-color: #6E2585;
      border-color: #6E2585; }
  .startover__cancel {
    font-size: 1rem;
    color: black;
    font-weight: 400; }
    .startover__cancel:hover {
      color: black; }
