.login {
  width: 500px;
  margin-top: 112px;
  padding-left: 21px;
  padding-right: 21px;
  padding-bottom: 70px; }
  .login__logo {
    padding-top: 27px;
    padding-bottom: 24px;
    border-bottom: 1px solid #E1E1E1; }
  .login__image {
    height: 38px; }
  .login__title {
    padding-top: 32px;
    padding-bottom: 28px; }
  .login__form {
    width: 372px; }
  .login__button {
    height: 50px;
    width: 200px;
    border-radius: 25px;
    background-color: #6E2585;
    border-color: #6E2585;
    font-size: 1.125rem;
    font-weight: 400; }
    .login__button:hover {
      background-color: #6E2585;
      border-color: #6E2585; }
    .login__button:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 189, 0.5); }
    .login__button__button:not(:disabled):not(.disabled):active {
      background-color: #6E2585;
      border-color: #6E2585; }
