.pagination {
  display: inline-flex; }

.paginator__item {
  cursor: pointer;
  padding-left: 5px;
  padding-right: 5px; }
  .paginator__item.active .paginator__link {
    background-color: #6E2585;
    border-color: #6E2585;
    color: white;
    z-index: unset; }
  .paginator__item:first-child .paginator__link {
    border-radius: 8px; }
  .paginator__item:last-child .paginator__link {
    border-radius: 8px; }

.paginator__link {
  box-sizing: border-box;
  height: 33px;
  width: 33px;
  border: 1px solid #E1E1E1;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 7px 64px 0 rgba(0, 0, 0, 0.07);
  color: #6E2585;
  padding: 0.35rem 0.75rem; }
  .paginator__link:hover {
    color: #6E2585; }

.paginator__break a {
  color: #6E2585; }
