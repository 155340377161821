.button-group {
  box-sizing: border-box;
  height: 46px;
  width: 46px;
  border: 1px solid #E9EFF4;
  border-radius: 4px;
  background-color: white;
  margin-left: 7px;
  margin-right: 7px; }
  .button-group--feature {
    margin-left: 13px;
    margin-right: 0px;
    margin-top: 7px; }
  .button-group:hover {
    background-color: rgba(110, 37, 133, 0.1);
    color: #6E2585;
    border: 1px solid rgba(110, 37, 133, 0.1); }
  .button-group:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 189, 0.5);
    color: #6E2585;
    border: 1px solid rgba(110, 37, 133, 0.1); }
  .button-group:not(:disabled):not(.disabled):active {
    background-color: rgba(110, 37, 133, 0.1);
    color: #6E2585;
    border: 1px solid rgba(110, 37, 133, 0.1); }
  .button-group span {
    font-size: 1.5rem;
    font-weight: 800;
    color: #6E2585;
    line-height: 1; }
