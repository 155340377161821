.preview {
  background-color: #EEEEEE;
  height: 100%;
  width: 1410px; }
  .preview__wrapper {
    width: 1040px;
    padding: 42px 0 0 0; }
  .preview__buttons {
    padding-bottom: 36px;
    text-align: right; }
  .preview__action:disabled {
    background-color: #6E2585;
    border-color: #6E2585; }

.preview-select {
  top: 20%;
  width: 960px;
  background: white;
  position: relative;
  margin: auto;
  text-align: center;
  border-radius: 6px; }
