.question-section {
  padding: 25px 80px; }
  .question-section--bordered {
    border-bottom: 1px solid #E1E1E1; }

.question-type {
  padding-top: 11px;
  padding-bottom: 11px; }

.answer-item {
  margin-left: 0px;
  margin-right: 0px; }
  .answer-item__name {
    max-width: 90%;
    margin-right: 1.6%; }
  .answer-item__upload {
    position: relative;
    text-align: center;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, 0%);
    -ms-transform: translate(-50%, 0%);
    transform: translate(-50%, 0%);
    cursor: pointer;
    line-height: 1; }
    .answer-item__upload img {
      height: 24px;
      display: block;
      margin: auto; }
    .answer-item__upload span {
      text-decoration: underline;
      font-size: 0.8rem; }
  .answer-item__image-result {
    max-height: 46px;
    cursor: pointer;
    max-width: 46px;
    margin: auto;
    display: block;
    padding-top: 7px;
    padding-bottom: 7px; }
  .answer-item__disabled {
    text-align: center;
    padding-top: 7px;
    padding-bottom: 7px; }
    .answer-item__disabled i {
      color: #F0E9F2; }

.custom-control-label {
  padding-left: 10px; }
  .custom-control-label:before {
    border: #6E2585 solid 1px;
    top: 0;
    width: 1.5rem;
    height: 1.5rem; }
  .custom-control-label:after {
    top: 0.125rem;
    width: 1.25rem;
    height: 1.25rem;
    left: -1.4rem; }

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #6E2585;
  background-color: #6E2585; }

.custom-control-input:checked ~ .custom-control-label {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 800; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("../../images/check_white.svg"); }

.addRange {
  box-sizing: border-box;
  border: none;
  border-radius: 50%;
  background-color: white;
  padding: 0px; }
  .addRange:hover {
    background-color: rgba(110, 37, 133, 0.1);
    color: #6E2585;
    border: none; }
  .addRange:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 189, 0.5);
    color: #6E2585;
    border: none; }
  .addRange:not(:disabled):not(.disabled):active {
    background-color: rgba(110, 37, 133, 0.1);
    color: #6E2585;
    border: none; }
  .addRange img {
    height: 36px;
    width: 36px; }

.answer-title {
  line-height: 1.7; }

.range-admin {
  justify-content: space-between;
  height: 100%;
  margin-bottom: auto;
  margin-top: auto; }
  .range-admin span {
    font-size: 16px; }

.range-input {
  width: 100%;
  height: 45px;
  border: 1px solid #D3D1D1; }
  .range-input:focus {
    box-shadow: unset;
    border: 1px solid #D3D1D1; }
