.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.2) !important; }

.helper {
  position: relative;
  background: white;
  outline: none;
  padding: 20px;
  height: 473px;
  width: 540px;
  margin: auto;
  top: 133px;
  left: 249px;
  padding: 2.2rem 2.8rem; }
  @media (max-width: 599px) {
    .helper {
      position: absolute;
      height: 100%;
      width: 82%;
      top: 0;
      right: 0;
      left: initial;
      font-size: 0.9rem; }
      .helper h4 {
        font-size: 1.2rem; } }
  .helper__button-wrapper {
    position: absolute;
    left: -60px; }
  .helper__button {
    width: 60px;
    height: 50px;
    background-color: #ede3f1;
    border-radius: 25px 0 0 25px;
    color: #6E2585;
    border: none; }
    .helper__button:hover {
      background-color: #ede3f1;
      color: #6E2585; }
    .helper__button:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 189, 0.5);
      color: #6E2585; }
    .helper__button:not(:disabled):not(.disabled):active {
      background-color: #ede3f1;
      color: #6E2585; }
      @media (max-width: 599px) {
        .helper__button:not(:disabled):not(.disabled):active {
          background-color: #F0E9F3; } }
    .helper__button span {
      font-size: 1.875rem;
      line-height: 1;
      padding-left: 8px; }
