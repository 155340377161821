.home-setup {
  width: 440px;
  padding-left: 0px;
  padding-right: 0px;
  color: black; }
  @media (max-width: 599px) {
    .home-setup {
      width: 100%;
      padding-left: 6%;
      padding-right: 6%; } }
  .home-setup__title {
    margin-bottom: 14px;
    text-align: center;
    font-family: "Nunito Sans", sans-serif;
    font-weight: 800;
    font-size: 2.1875rem; }
    @media (max-width: 599px) {
      .home-setup__title {
        font-size: 1.875rem; } }
  .home-setup__description {
    margin-bottom: 2rem;
    text-align: center; }
  .home-setup__image {
    text-align: center;
    margin-bottom: 20px; }
    .home-setup__image img {
      max-height: 300px;
      max-width: 300px; }
      @media (max-width: 599px) {
        .home-setup__image img {
          width: 90%; } }
