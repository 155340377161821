.wizard {
  background-color: white;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  width: 1040px;
  padding: 0;
  position: relative; }
  @media (max-width: 599px) {
    .wizard {
      width: 100%;
      height: 100%;
      box-shadow: none;
      border-radius: unset;
      position: static; } }
  .wizard__progress {
    height: 9px;
    background-color: #D3BDDA;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px; }
    @media (max-width: 599px) {
      .wizard__progress {
        border-top-left-radius: unset;
        border-top-right-radius: unset; } }
    .wizard__progress .progress-bar {
      background-color: #6E2585; }
  .wizard__wrapper {
    padding: 30px 30px 0 30px;
    height: 100%; }
    @media (max-width: 599px) {
      .wizard__wrapper {
        padding: 4% 0 0 0; } }
  .wizard__title {
    width: 500px;
    text-align: center; }
    @media (max-width: 599px) {
      .wizard__title {
        width: 100%;
        padding-left: 10%;
        padding-right: 10%; } }
    @media (max-width: 599px) {
      .wizard__title h4 {
        font-size: 1.25rem; } }
  .wizard__message {
    font-size: 0.875rem; }
  .wizard__layout {
    text-align: center;
    padding-top: 4%;
    padding-bottom: 4%;
    height: 100%;
    width: 100%;
    overflow: hidden; }
    @media (max-width: 599px) {
      .wizard__layout {
        position: absolute;
        padding-top: 15px;
        padding-left: 7%;
        padding-right: 7%;
        height: -moz-calc(100% - 221px);
        height: -webkit-calc(100% - 221px);
        height: calc(100% - 221px);
        overflow-y: auto; } }
    .wizard__layout--icon {
      width: 928px; }
      @media (max-width: 599px) {
        .wizard__layout--icon {
          width: 100%; } }
    .wizard__layout--card {
      padding-top: 8%; }
    .wizard__layout--grid {
      padding-left: 138px;
      padding-right: 138px;
      padding-top: 4%;
      width: 100%; }
      @media (max-width: 599px) {
        .wizard__layout--grid {
          padding-left: 7%;
          padding-right: 7%; } }
    .wizard__layout--slide {
      margin-top: 55px;
      box-sizing: border-box;
      height: 196px;
      width: 851px;
      border: 1px solid #D3D1D1;
      border-radius: 6px;
      background-color: #FFFFFF;
      box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
      padding-left: 45px;
      padding-right: 45px;
      padding-top: 8%; }
      @media (max-width: 599px) {
        .wizard__layout--slide {
          width: 100%; } }
  .wizard__option {
    box-sizing: border-box;
    border: 1px solid #D3D1D1;
    border-radius: 6px;
    background-color: white;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    font-size: 1rem;
    cursor: pointer;
    line-height: 1.25rem; }
    .wizard__option--card {
      height: 196px;
      width: 146px;
      margin-left: 15px;
      margin-right: 15px;
      margin-bottom: 15px;
      display: inline-block;
      vertical-align: middle; }
      @media (max-width: 599px) {
        .wizard__option--card {
          display: inherit;
          width: 100%;
          height: 71px;
          margin-bottom: 15px;
          margin-left: 0;
          margin-right: 0px; } }
      .wizard__option--card:first-child {
        margin-left: 0; }
      .wizard__option--card:nth-child(6n) {
        margin-left: 0; }
      .wizard__option--card:last-child {
        margin-right: 0; }
      .wizard__option--card:nth-child(5n) {
        margin-right: 0; }
      .wizard__option--card-text {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis; }
        @media (max-width: 599px) {
          .wizard__option--card-text {
            width: 84%; } }
    .wizard__option--icontext {
      height: 86px;
      width: 281px;
      float: left;
      margin: 0 9px 15px 9px; }
      @media (max-width: 599px) {
        .wizard__option--icontext {
          margin: auto;
          height: 71px;
          width: 100%;
          margin-bottom: 8px;
          float: none; } }
      .wizard__option--icontext-content {
        text-align: left;
        height: 100%; }
      .wizard__option--icontext-elem {
        height: 100%;
        position: relative;
        text-align: left; }
        .wizard__option--icontext-elem img {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 25%;
          max-width: 50px;
          max-height: 50px; }
        .wizard__option--icontext-elem span {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          font-size: 1rem;
          overflow: hidden;
          display: -webkit-box;
          /* autoprefixer: off */
          -webkit-box-orient: vertical;
          /* autoprefixer: on */
          -webkit-line-clamp: 3;
          width: 94%; }
    .wizard__option--grid {
      height: 126px;
      width: 146px;
      float: left;
      margin: 0 15px 15px 15px;
      padding-left: 10px;
      padding-right: 10px; }
      @media (max-width: 599px) {
        .wizard__option--grid {
          width: 46%;
          height: 101px;
          margin: 0 6px 6px 6px; } }
      .wizard__option--grid-text {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis; }
        @media (max-width: 599px) {
          .wizard__option--grid-text {
            width: 84%; } }
    .wizard__option--plain {
      width: 771px;
      margin-bottom: 12px;
      line-height: 1; }
      @media (max-width: 599px) {
        .wizard__option--plain {
          width: 100%; } }
      .wizard__option--plain-text {
        text-align: left;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, 0%);
        margin-top: 18px;
        margin-bottom: 18px;
        width: 100%; }
    .wizard__option--checked {
      border: 2px solid #6E2585;
      background-color: #FBEFFF;
      font-family: "Nunito Sans", sans-serif;
      font-weight: 800;
      font-size: 1rem; }
  .wizard__navigation {
    height: 80px;
    border-top: 1px solid #D2D2D2;
    margin-top: 20px;
    background-color: white;
    width: 100%;
    bottom: 0;
    padding: 14px 16px; }
    @media (max-width: 599px) {
      .wizard__navigation {
        position: fixed; } }
  .wizard__secondary {
    height: 51px;
    border: 1px solid #6E2585;
    background-color: white;
    color: #6E2585; }
    .wizard__secondary--back {
      border-radius: 25px;
      width: 129px;
      font-size: 1.125rem;
      text-align: right;
      padding: 0 17px;
      line-height: 1;
      margin-right: 16px; }
      @media (max-width: 599px) {
        .wizard__secondary--back {
          width: 51px;
          text-align: center;
          margin-right: 15px; } }
      .wizard__secondary--back i {
        float: left;
        font-size: 1rem;
        padding-top: 2px; }
        @media (max-width: 599px) {
          .wizard__secondary--back i {
            float: none;
            padding-top: 0; } }
      @media (max-width: 599px) {
        .wizard__secondary--back span {
          display: none; } }
    .wizard__secondary--reset {
      border-radius: 25px;
      width: 51px; }
    .wizard__secondary--result {
      border-radius: 25px;
      width: 139px;
      font-size: 1.125rem;
      text-align: center;
      line-height: 1;
      margin-left: 20px; }
    .wizard__secondary:hover {
      background-color: rgba(110, 37, 133, 0.1);
      color: #6E2585;
      border: 1px solid #6E2585; }
    .wizard__secondary:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 189, 0.5);
      color: #6E2585; }
    .wizard__secondary:not(:disabled):not(.disabled):active {
      background-color: rgba(110, 37, 133, 0.1);
      color: #6E2585;
      border: 1px solid #6E2585; }
  .wizard__right {
    height: 50px !important;
    text-align: left;
    line-height: 1;
    width: 230px !important;
    font-family: "Nunito Sans", sans-serif;
    font-weight: 800;
    padding: 0 24px;
    margin-right: 0 !important; }
    @media (max-width: 599px) {
      .wizard__right {
        width: 51px !important;
        font-size: 1rem;
        text-align: center;
        padding: 0 17px; } }
    .wizard__right:disabled {
      background-color: rgba(0, 0, 0, 0.4);
      border-color: unset; }
      .wizard__right:disabled:hover {
        background-color: rgba(0, 0, 0, 0.4);
        border-color: unset; }
    .wizard__right i {
      float: right;
      font-size: 1rem; }
    @media (max-width: 599px) {
      .wizard__right span {
        display: none; } }
  .wizard__backWizard {
    height: 50px !important;
    width: 187px !important;
    text-align: right;
    padding: 0 19px;
    line-height: 1;
    margin-right: 16px; }
    @media (max-width: 599px) {
      .wizard__backWizard {
        width: 51px !important; }
        .wizard__backWizard span {
          display: none; } }
    .wizard__backWizard:disabled {
      background-color: #6E2585;
      border-color: #6E2585; }
    .wizard__backWizard i {
      float: left;
      font-size: 1rem;
      padding-top: 2px; }
  .wizard__help {
    float: right;
    padding-top: 35px; }
    @media (max-width: 599px) {
      .wizard__help {
        padding-top: 17px;
        right: 0;
        z-index: 10; } }
    .wizard__help-button {
      width: 60px;
      height: 50px;
      background-color: rgba(110, 37, 133, 0.1);
      border-radius: 25px 0 0 25px;
      color: #6E2585;
      border: none; }
      @media (max-width: 599px) {
        .wizard__help-button {
          background-color: #F0E9F3; } }
      .wizard__help-button:hover {
        background-color: rgba(110, 37, 133, 0.1);
        color: #6E2585; }
      .wizard__help-button:focus {
        box-shadow: 0 0 0 0.2rem rgba(220, 53, 189, 0.5);
        color: #6E2585; }
      .wizard__help-button:not(:disabled):not(.disabled):active {
        background-color: rgba(110, 37, 133, 0.1);
        color: #6E2585; }
  .wizard__check {
    display: none;
    position: absolute;
    text-align: end; }
    @media (max-width: 599px) {
      .wizard__check {
        position: static;
        float: right; } }
    .wizard__check--card {
      width: 122px; }
      @media (max-width: 599px) {
        .wizard__check--card {
          width: 28px; } }
    .wizard__check--icontext {
      width: 256px; }
      @media (max-width: 599px) {
        .wizard__check--icontext {
          width: 78%;
          position: absolute; } }
    .wizard__check--grid {
      width: 122px; }
      @media (max-width: 599px) {
        .wizard__check--grid {
          width: 34%;
          position: absolute; } }
      @media (max-width: 599px) {
        .wizard__check--grid img {
          padding-left: unset;
          padding-top: 6px !important; } }
    .wizard__check--plain {
      width: 745px; }
      @media (max-width: 599px) {
        .wizard__check--plain {
          width: 78%;
          position: absolute; } }
      @media (max-width: 599px) {
        .wizard__check--plain img {
          padding-left: unset;
          padding-top: 6px !important; } }
    .wizard__check img {
      padding-top: 5px; }
      @media (max-width: 599px) {
        .wizard__check img {
          padding-left: unset;
          padding-top: 20px; } }
    .wizard__check--active {
      display: block;
      text-align: right; }
  .wizard__result {
    color: #6E2585;
    text-align: center; }
    @media (max-width: 599px) {
      .wizard__result {
        padding-left: 0px;
        padding-right: 0px; } }
    .wizard__result--none {
      opacity: 0.5;
      font-family: "Nunito Sans", sans-serif;
      font-weight: 800; }
    @media (max-width: 599px) {
      .wizard__result--desktop {
        display: none; } }
    .wizard__result--mobile {
      display: none; }
      @media (max-width: 599px) {
        .wizard__result--mobile {
          display: inherit; } }
  .wizard__resume {
    height: 100%; }
    .wizard__resume--value {
      font-family: "Nunito Sans", sans-serif;
      font-weight: 800; }
    @media (max-width: 599px) {
      .wizard__resume--details {
        padding-left: 0px;
        padding-right: 0px; } }
  .wizard__devices {
    display: none; }
    @media (max-width: 599px) {
      .wizard__devices {
        display: block; } }
  @media (max-width: 599px) {
    .wizard__products--number {
      padding-right: 30%; } }
  @media (max-width: 599px) {
    .wizard__products--devices {
      display: none; } }
  @media (max-width: 599px) {
    .wizard__seeresults {
      width: auto;
      font-size: 0.75rem;
      margin-left: 0px; } }
