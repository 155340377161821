.product-container__search {
  width: 401px;
  position: relative; }

.product-container__button-search {
  position: absolute;
  display: block;
  right: 1rem;
  -webkit-transition: opacity 0.15s ease-in;
  transition: opacity 0.15s ease-in;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: .8;
  background: rgba(0, 0, 0, 0);
  border: none;
  cursor: pointer;
  line-height: 1; }

.product-container__icon {
  color: #6E2585; }

.product-container__sort {
  width: 221px; }
