.button-add {
  background-color: rgba(110, 37, 133, 0.1);
  border-radius: 25px;
  color: #6E2585;
  width: 200px;
  height: 40px;
  border: none;
  font-size: 1.125rem;
  font-weight: 300;
  padding-left: 25px; }
  .button-add:hover {
    background-color: rgba(110, 37, 133, 0.1);
    color: #6E2585; }
  .button-add:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 189, 0.5);
    color: #6E2585; }
  .button-add:not(:disabled):not(.disabled):active {
    background-color: rgba(110, 37, 133, 0.1);
    color: #6E2585; }
  .button-add__icon {
    float: right;
    padding-top: 4px;
    font-size: 1rem;
    padding-right: 4px; }
