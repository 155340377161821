.home-container {
  margin-top: 54px;
  width: 560px;
  padding: 36px 65px; }
  @media (max-width: 599px) {
    .home-container {
      width: 100%;
      padding: 1% 4%;
      height: 100%; } }
  .home-container button {
    height: 50px;
    width: 230px;
    font-size: 1.125rem;
    font-weight: 600;
    font-family: "Nunito Sans", sans-serif;
    margin-right: 0;
    text-align: left;
    line-height: 1;
    padding: 0 24px; }
    @media (max-width: 599px) {
      .home-container button {
        padding: 0 17px; } }
    .home-container button i {
      float: right;
      font-size: 1rem; }
