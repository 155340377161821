.navadmin {
  padding: 0rem 1rem;
  height: inherit; }
  .navadmin__logo {
    height: 38px; }
  .navadmin__collapse {
    height: inherit; }
  .navadmin__nav {
    height: inherit;
    border-left: 1px solid #D2D2D2;
    padding-left: 0.4rem; }
  .navadmin__navitem {
    height: inherit;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    color: #4A4A4A;
    font-size: 1.125rem;
    text-align: center; }
  .navadmin__link {
    height: inherit;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-left: 0.4rem !important;
    padding-right: 0.4rem !important; }
    .navadmin__link:hover {
      border-bottom: 3px solid #6E2585; }
    .show > .navadmin__link,
    .active > .navadmin__link, .navadmin__link.show, .navadmin__link.active {
      border-bottom: 3px solid #6E2585;
      font-family: "Nunito Sans", sans-serif;
      font-weight: 800; }
  .navadmin__imgprofile {
    height: 38px;
    width: 38px;
    margin-left: 15px;
    margin-right: 15px; }
  .navadmin__logout {
    font-size: 1rem;
    color: black;
    font-weight: 400; }
    .navadmin__logout:hover {
      color: black; }
