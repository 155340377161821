.product-tabs {
  width: 670px; }

.product-feature__options {
  font-size: 1rem;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap; }

.product-modal {
  top: 25%;
  background: white;
  position: relative;
  width: 640px;
  height: 480px;
  margin: auto;
  border-radius: 6px;
  border: 1px solid #6E2585; }
  .product-modal__header {
    height: 50px;
    padding: 15px 30px 10px 30px; }
  .product-modal__options {
    padding: 0px 10px;
    height: 350px;
    overflow-y: auto; }
  .product-modal__footer {
    height: 78px;
    padding: 19px 30px;
    border-top: 1px solid #D2D2D2; }
  .product-modal__resume {
    padding-top: 8px;
    padding-bottom: 8px; }
  .product-modal__buttons {
    text-align: right;
    padding-left: 0px;
    padding-right: 0px; }
  .product-modal__cancel {
    font-size: 1rem;
    color: black;
    font-weight: 400; }
    .product-modal__cancel:hover {
      color: black; }
